import React, { useState } from "react";
import { useAsync } from 'react-async-hook';
import { useGlobalContext } from "Context/Global";
import Table from "Components/Common/Table/Table";
import MonthDropdown, { getDaysInMonth } from "Components/Common/MonthDropdown/MonthDropdown";
import { Spinner } from "Components/Common/LoadingSpinner/Spinner";
import { TableBody } from "./Table";
import { TableHeading } from "./Table";
import { Stats } from "./Stats";
import { GetSalesDashboard } from "Services/API/Admin.service";
import { ReactComponent as SadFaceIcon } from "Assets/SadFace.svg";
import "./Dashboard.css";
import "Colors.css";

export function Dashboard() {
  const { getCognitoId } = useGlobalContext();
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const dashboardAsync = useAsync(GetSalesDashboard, [
    getCognitoId(),
    `${month}/01/${year}`,
    `${month}/${getDaysInMonth(year, month)}/${year}`
  ]);

  const data = dashboardAsync?.result ?? []

  return (
    <>
      {dashboardAsync.error && (
        <>
          <div className="flex flex-col items-center justify-center h-full">
            <SadFaceIcon className="h-16 w-16  animate-bounce text-red-800" />
            <p className="mt-1 text-md font-medium animate-pulse text-red-800">
              {dashboardAsync.error?.message}
            </p>
          </div>
        </>
      )}
      {dashboardAsync.loading && <Spinner />}
      {!dashboardAsync.loading && !dashboardAsync.error && (
        <div className="flex flex-col gap-6 p-4">

          <section className="flex" style={{ justifyContent: 'space-between' }}>
            <span className="text-base text-xl font-semibold sapphire">
              Referrals
            </span>
            <MonthDropdown
              month={month-1}
              year={year}
              callback={(val) => setMonth(val + 1)}
            />
          </section>

          <Stats data={data} month={month} year={year} />

          {!dashboardAsync.loading || !dashboardAsync?.result?.length
            ? (
              <Table
                heading={<TableHeading />}
                body={<TableBody data={data} />}
              />
            )
            : 'No referrals yet'
          }
        </div>
      )}
    </>
  );
}
