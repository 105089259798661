import { formatDate2 } from "../../Helpers/DateFormat";

const TABLE_HEADINGS = [
  // "Invited by Email",
  "Signed Up",
  "Total Visits",
  "Payout",
];

export const TableHeading = () =>
  TABLE_HEADINGS.map((head, index) => (
    <th
      key={index}
      scope="col"
      className="bali_hai px-6 py-3 text-left text-sm font-medium"
    >
      {head}
      {["Created", "Details"].includes(head) ? (
        <small className="ml-1">(PST)</small>
      ) : (
        ""
      )}
    </th>
  ));

export const TableBody = ({ data, onClick }) =>
  data.map((item, index) => {
    const patient = item?.visits?.[0] ?? {};
    return (
      <tr key={index}>
        {/* <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
          <div>{patient.email ? "Yes" : "No"}</div>
        </td> */}
        <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
          {formatDate2(patient.accountCreated, "MM/DD/YY")}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
          {item?.visits?.length || 0}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm sapphire">
          {`$${item?.totalCommission ?? 0}`}
        </td>
      </tr>
    );
  });
