export const tokenFormatter = (token) => {
  const formattedTokenesult = {
    idToken: token?.IdToken,
    accessToken: token.AccessToken,
    tokenExpiry: token.ExpiresIn,
    refreshToken: token.RefreshToken,
    cognitoId: token?.cognitoId,
  };

  return formattedTokenesult;
};
export const isPasswordValid =(password)=> {
  var passwordRegex  = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );

  if (!password || password.length < 8) {
    return false;
  }
  if (passwordRegex.test(password)) {
    return true;
  }
    return false;

}