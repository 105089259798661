import MainLayout from "Layouts/MainLayout/MainLayout";
import { Support } from "Components/Support/Support";
// import { TabsPanel } from "Components/Provider/TabsPanel/TabsPanel";
import "Pages/Invite/DetailPage.css";

export function SupportPage() {
  return (
    <MainLayout>
      <div className="DetailPage_Wrapper flex flex-col gap-4 overflow-x-hidden">
        <Support />
      </div>
    </MainLayout>
  );
}
