import { MARKETING_PATH } from "Constants/Views";
import { MarketingPage } from "Pages/Marketing/Marketing";
import { Route, Switch } from "react-router";
import { useGlobalContext } from "Context/Global";

export default function Marketing() {
//  const { getAdminRights } = useGlobalContext();
//  const { providerRights } = getAdminRights();
  return (
    <Switch>
      <Route
        exact
        path={MARKETING_PATH}
        render={() => <MarketingPage />}
      />
    </Switch>
  );
}
