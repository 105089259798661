import { PAYOUTS_PATH } from "Constants/Views";
import { PayoutsPage } from "Pages/Payouts/Payouts";
import { Route, Switch } from "react-router";
import { useGlobalContext } from "Context/Global";

export default function Payouts() {
//  const { getAdminRights } = useGlobalContext();
//  const { providerRights } = getAdminRights();
  return (
    <Switch>
      <Route
        exact
        path={PAYOUTS_PATH}
        render={() => <PayoutsPage />}
      />
    </Switch>
  );
}
