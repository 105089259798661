/* eslint-disable jsx-a11y/alt-text */
import WelcomeImage from "Assets/welcome.png";
import { ReactComponent as InstaCureLogo } from "Assets/InstacuredLoginLogo.svg";
import "../../Colors.css";

export default function SignInLayout({ children }) {
  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-2 min-h-screen max-h-screen min-w-full max-w-full">
      <span className="bg-blue-chill  flex items-center justify-center hidden md:block">
        <img
          className="object-cover"
          // className="object-cover lg:h-3/4 2xl:h-4/5"
          src={WelcomeImage}
        />
      </span>

      <div className="flex-1 flex justify-center sm:pt-12 lg:pt-16 xl:pt-20 2xl:pt-56">
        <LoginSection>{children}</LoginSection>
      </div>
    </div>
  );
}

const LoginSection = ({ children }) => {
  return (
    <div className="max-h-44 grid grid-rows-2 grid-flow-col gap-4 mt-20 sm:mt-0">
      <div>
        <InstaCureLogo className="h-14 w-auto " />
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};
