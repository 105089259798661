import React from "react";
import ClassNames from "Helpers/Common";
import "Colors.css";
import "./InputField.css";

export const InputFieldHook = ({
  id,
  label,
  type = "text",
  placeholder = "",
  isRequired = false,
  maxLength,
  onBlur,
  isDisabled = false,
  value,
  onChange,
  children,
  onKeyDown,
  onIconClick,
  register,
  registerOptions = {},
  name,
  errors,
  errorMessages,
}) => {
  return (
    <div className="InputField_MainWrapper">
      {label && (
        <label htmlFor={id} className="sapphire block text-sm font-semibold">
          {label}
        </label>
      )}
      <div
        className={ClassNames(
          "InputField_FieldWrapper border rounded-md",
          errors?.[name]
            ? "border-red-500 focus:ring-red-500 focus:border-red-500"
            : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500",
          isDisabled && "bg-gray-100",
        )}
      >
        <span onClick={onIconClick?.()}>{children}</span>
        <input
          type={type}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={isDisabled}
          className={ClassNames(
            "appearance-none block w-full placeholder-gray-400 focus:outline-none sm:text-sm",
            isDisabled && "cursor-not-allowed",
          )}
          {...register(name, {required: isRequired, ...registerOptions })}
        />
      </div>
      {errors?.[name]?.type === 'pattern' && (
        <div className="InputField_ErrorWrapper text-sm text-red-600">
          {errorMessages?.pattern}
        </div>
      )}
    </div>
  );
};
