import { EMAIL_INVITES_PATH } from "Constants/Views";
import { InvitePage } from "Pages/Invite/Invite";
import { Route, Switch } from "react-router";
import { useGlobalContext } from "Context/Global";

export default function Invite() {
//  const { getAdminRights } = useGlobalContext();
//  const { providerRights } = getAdminRights();
  return (
    <Switch>
      <Route
        exact
        path={EMAIL_INVITES_PATH}
        render={() => <InvitePage />}
      />
    </Switch>
  );
}
