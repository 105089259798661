import { Fragment, useEffect, useRef, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CalendarIcon } from '@heroicons/react/outline'

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec', 
]

export const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

const MonthDropdown = ({ month: monthArg, year: yearArg, callback }) => {
  const [month, setMonth] = useState(monthArg)
  const [year, setYear] = useState(yearArg)
  const currentMonth = new Date().getMonth()
  
  const getStyles = (item) => {    
    if (currentMonth === months.indexOf(item)) {
      return { backgroundColor: '#eee' }
    } else if (months.indexOf(item) === monthArg) {
      return { backgroundColor: '#37828b', color: 'white' }
    } else {
      return {}
    }
  }
  
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {`${months[month]} ${year}`}
            <CalendarIcon
              className="-mr-1 ml-2 h-5 w-5 text-violet-200 "
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5">
            <div className="px-1 py-1 ">
              {/*<div className="rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">*/}
              {/*  <label className="cursor-pointer rounded-full px-2.5 py-1">*/}
              {/*    <span>{year}</span>*/}
              {/*  </label>*/}
              {/*</div>*/}
              <div className="grid grid-cols-1 gap-1 sm:grid-cols-3">
                {months.map(item => ( 
                  <Menu.Item>
                    {(options) => {
                      return (
                        <button
                          className={`text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm`}
                          style={getStyles(item)}
                          onClick={(e) => {
                            const val = months.indexOf(e.target.textContent)                          
                            setMonth(val)
                            callback(val)
                          }}
                        >
                          {item}
                        </button>
                      )            
                    }}
                  </Menu.Item>
                ))}
            </div>
           </div>
         </Menu.Items>
       </Transition>
     </Menu>      
    </>
  )
}

export default MonthDropdown