import ClassNames from "Helpers/Common";
import "./Button.css";
export const Button = ({
  btnText,
  isDisabled = true,
  onClick,
  className,
  isLoading = false,
  children,
  id = 1,
  variant = "Default",
  type = "button",
}) => {
  const backgroundStyle = isLoading || isDisabled ? { backgroundColor: '#37828b' } : {}
  return (
    <button
      type={type}
      key={id}
      disabled={isDisabled}
      onClick={() => onClick?.()}
      className={ClassNames(
        "flex justify-center items-center border border-transparent rounded-md text-sm font-normal focus:outline-none",
        isDisabled
          ? "bg_alice_blue light_grayish_navy border  border-gray-300"
          : variant !== "Default"
            ? variant !== "Delete"
              ? "bg_alice_blue light_grayish_navy border  border-gray-300 text-black-eclipse"
              : "bg-red-500"
            : "bg-blue-chill text-white",
        className,
      )}
      style={backgroundStyle}
    >
      {children}
      <div className="flex items-center justify-center gap-2 px-2">
        {isLoading ? (
          <div className="w-4 h-4 border-l-2 border-b-2 border-white rounded-full animate-spin" />
        ) : (
          btnText && <span>{btnText}</span>
        )}
      </div>
    </button>
  );
};
