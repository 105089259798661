import MainLayout from "Layouts/MainLayout/MainLayout";
// import { Payouts } from "Components/Payouts/Payouts";
// import { TabsPanel } from "Components/Provider/TabsPanel/TabsPanel";
import "Pages/Invite/DetailPage.css";

export function MarketingPage() {
  return (
    <MainLayout>
      <div className="DetailPage_Wrapper flex flex-col gap-4 overflow-x-hidden">
        <span className="text-base text-xl font-semibold sapphire">
          Marketing
        </span>
        Coming soon!
      </div>
    </MainLayout>
  );
}
