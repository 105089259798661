import { useEffect } from 'react'


export function Support() {
  useEffect(() => {
    if (window) {
      const intervalId = setInterval(() => {
        if (window.Tawk_API?.getStatus() === 'online') {
          popup();
        }
      }, 2000)

      const popup = () => {
        clearInterval(intervalId)
        window.Tawk_API.popup();
      }
    }
  }, [])

  return (
    <div>
      <title>InstaCured - Loyalty Support Chat</title>
      <meta name="robots" content="noindex,nofollow" />

      <div className="div-2 contact-page" style={{ top: 0 }}>
        {/*  <Nav /> */}
        <div className="h-screen flex mt-3 md:mt-20 lg:mt-0 lg:items-center lg:justify-center">
          <div className="grid grid-cols-1 bg-white-secondary w-[80rem] rounded-2xl" style={{background: '#f2f8ff'}}>
            <div className="bg-zircon h-[24rem] md:h-[30rem] md:w-[35rem] rounded-2xl md:rounded-none md:rounded-l-2xl flex flex-col justify-center items-center md:justify-start md:items-start gap-4 p-14 md:p-20 text-center md:text-left ml-6 mr-6 md:m-0" style={{ height: '100%', width: '100%', background: '#f2f8ff' }}>
              <div className="text-c2xl1 font-medium" style={{ fontSize: '32px' }}>
                Loyalty Support Chat
              </div>
              <div className="text-c2xl1 font-medium" style={{ fontSize: '18px', lineHeight: '26px' }}>
                Click Chat Icon below if chat does not load
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
