import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useGlobalContext } from "Context/Global";
import ClassNames from "Helpers/Common";

export default function NavBar() {
  const { resetCredentials, getSalesUser } = useGlobalContext();
  
  const { userData } = getSalesUser()
  const name = `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`
  return (
    <Disclosure as="nav" className="bg-white border-b border-gray-300">
      {({ open }) => (
        <>
          <div className="max-w-full  flex  items-center justify-end h-16 pr-6">
            <div className="  flex justify-end pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 ">
              {name}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className=" flex text-sm rounded-full focus:outline-none ">
                    <span className="sr-only">Open user menu</span>
                    <ChevronDownIcon className="h-6 w-6 bg-white focus:outline-" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={() => {
                            resetCredentials();
                            window.location.reload();
                          }}
                          className={ClassNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Log out
                        </span>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
