import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAsyncCallback } from 'react-async-hook';
import { emailRegex, validateEmail } from "Constants/Validate"
import Modal from "Components/Modal/Modal";
import { ReactComponent as SuccessIcon } from "Assets/Activate.svg";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { ReactComponent as EmailIcon } from "Assets/Mail.svg";
import { InputFieldHook } from "Components/Common/InputFieldHook/InputFieldHook";
import { SendBulkInvite } from "Services/API/Admin.service";
import "./FormsCommonCss/Form.css";
import "Colors.css";

export function SendInvites() {
  const { register, handleSubmit, formState, reset } = useForm();
  const emails = [...Array(10).keys()]

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [apiError, setApiError] = useState(false);

  const submit = handleSubmit( async(data) => {
    setApiError(null)
    const payload = Object.values(data).filter(i => i !== '')
    const isFormValid = payload.length > 0 && payload.every(i => validateEmail(i))

    console.log('SendBulkInvite -> before', { isFormValid, formState, data })

    if (isFormValid) {
      setSubmitting(true)
      const res = await SendBulkInvite(payload);
      const isSuccess = res?.data?.errors?.length === 0
      console.log('SendBulkInvite -> response ->', res)
      if (isSuccess) {
        setSubmitting(false)
        setIsModalOpen(true)
        reset()
      } else {
        setSubmitting(false)
        setApiError(res?.data?.errors?.[0])
      }
    }
  })

  const SubmitButton = ({ onClick }) => {
    const asyncOnClick = useAsyncCallback(onClick)
    return (
      <Button
        onClick={asyncOnClick.execute}
        isLoading={submitting}
        type="submit"
        className="h-8 w-32"
        btnText={submitting ? '...' : 'Send Invites'}
        isDisabled={submitting}
      />
    )
  }

  return (
    <>
      <form onSubmit={() => {}}>
        <span className="text-base text-xl font-semibold sapphire">
          Email Invites
        </span>
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
        <div className="FormWrapper" style={{ margin: '20px 0' }}>
          <div className="grid grid-cols-2 gap-6">
            {emails.map((i) => (
              <div className="col-span-2 lg:col-span-1">
                <InputFieldHook
                  register={register}
                  registerOptions={{ pattern: emailRegex }}
                  name={`patient${i+1}`}
                  label={`Patient ${i+1}`}
                  placeholder="name@example.com"
                  errors={formState.errors}
                  errorMessages={{ pattern: 'Invalid email' }}
                >
                  <EmailIcon />
                </InputFieldHook>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-start ml-5" style={{marginBottom: '20px'}}>
          <SubmitButton onClick={submit} />
        </div>
      </form>
      <Modal
        open={isModalOpen}
        isLoading={false}
        icon={<SuccessIcon />}
        btnText="Close"
        heading="Emails sent!"
        setOpen={() => setIsModalOpen(false)}
      />
    </>
  );
}
