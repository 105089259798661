import MainLayout from "Layouts/MainLayout/MainLayout";
import { SendInvites } from "Components/SendInvites/SendInvites";
// import { TabsPanel } from "Components/Provider/TabsPanel/TabsPanel";
import "Pages/Invite/DetailPage.css";

export function InvitePage() {
  return (
    <MainLayout>
      <div className="DetailPage_Wrapper flex flex-col gap-4 overflow-x-hidden">
        <SendInvites/>
      </div>
    </MainLayout>
  );
}
