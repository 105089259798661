import { useState, useEffect } from "react";
import { Button } from "Components/Common/Button/Button";
import {
  BASE_DASHBOARD_PATH,
  LOGIN_VIEW,
  DASHBOARD_VIEW,
} from "Constants/Views";
import { tokenFormatter } from "Helpers/Login";
import { useLoginContext } from "Context/Login";
import { useGlobalContext } from "Context/Global";
import { SendVerificationReq, SendLogInReq } from "Services/API/Login.service";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import { ApiError } from "Components/Common/ApiError";
import ClassNames from "Helpers/Common";
import { GetAdminPersonalDetails } from "Services/API/Admin.service";
import OtpInput from "react-otp-input";
import "./Verification.css";
import "Colors.css";

const TOTAL_VERIFICATION_CHARS = 4;

export default function Verification() {
  const { setActiveView, getCredentials, resetCredentials, setCredentials } =
    useLoginContext();
  const { verification } = getCredentials();
  const { setTokenDetails, setAdminRights, setAdminDetail } =
    useGlobalContext();
  const [codeVerificationChars, setCodeVerificationChars] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setIsDisabled(!(codeVerificationChars.length === TOTAL_VERIFICATION_CHARS));
  }, [codeVerificationChars]);

  const handleSubmit = () => {
    const { email, password } = getCredentials();
    setIsLoading(true);
    if (
      email &&
      password &&
      codeVerificationChars.length === TOTAL_VERIFICATION_CHARS
    ) {
      
//      "email":"instaomer807@yopmail.com",
//        "authCode":"1918",
//        "newPassword":"Test12345678@",
//        "confirmNewPassword": "Test12345678@"
      
      SendVerificationReq({
        email,
//        password,
        newPassword: password,
        confirmNewPassword: password,
        authCode: codeVerificationChars,
      })
        .then(async ({ data: { result } }) => {
          
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> data", result)
          
          
          const wave = {
    "auth": {
        "AccessToken": "eyJraWQiOiJHRjA2WlI5NFJ4cTBPQ1JEKzRJVzhKNXYzd3FsTkd1Y2xOcmlBSTYrR3Q0PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIwYjcyN2U0Ny1mMzMyLTQ4ZGMtYTY5ZC0xZTE0NDFiMDJjMDUiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0xLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMV9zbGozNFNnZUsiLCJjbGllbnRfaWQiOiI0ZGVrdWkwODZtbXZzZzYxOXUyam1kM2w1cCIsIm9yaWdpbl9qdGkiOiIwNWYxY2Q5Zi1lNDZlLTRjMjktYTZmZi1hMWU1Nzk1NzZhYmUiLCJldmVudF9pZCI6IjkxYmQ3MmIyLTVjZGMtNDU3NS04Y2QyLTkwMTI0ZThiMzc2OSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MjQwMjY5ODMsImV4cCI6MTcyNDAzNDE4MywiaWF0IjoxNzI0MDI2OTgzLCJqdGkiOiI2MGU3NGFkMi05ZjUzLTQxMmItOGUzYi0zYjQ0MTZhNzBhNDIiLCJ1c2VybmFtZSI6IjBiNzI3ZTQ3LWYzMzItNDhkYy1hNjlkLTFlMTQ0MWIwMmMwNSJ9.TcuEHqJIvi_kAdNdsNq93FA2PnXqc5wbua1qpWb6Go62QBwO24-kBiaCV7olaIK6-w22IZMu_u3-qBrP_tw6XE6CGCO2ZmBbSyHMcM7LI8OJl_TxfPEEFui32wYOQR9V2t1AsgqOmCrZFE0sGA_9e5hNB64wUZAUdPeun5CV19cG3RgYQ2WyEM3Zd8ezBVnd_Vq69p2VT3O9X_WGX4ps1u6WeKFdbcwbaDTp49yF53mjdXZ2KC8RfKzFe2YRxsHzxib7PoctEQjuAlDfHiTpoam8Z8Dc4OOWuUj-wH6KjyyHTIDP4BNg6FGZx-nzYdujxENlPvST5PYLg6Vz-mJ9qA",
        "ExpiresIn": 7200,
        "TokenType": "Bearer",
        "RefreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.nX95qes_AtpAxHDkB-iCsjEDTeSkzMzW7l53iVG7tpV6dO5NU63p5nVG1QQhEekW5K44BforNzNT7mY7a6OQsqW5WBOv0yOdp8PVvmYhm33a9zYsMgI7Jj1zhREJhFF-FsBBoO2qjzAE6515gfS6As-wxxauw_QeYwjwauirOclSywQbNDXMfF0wDzEH-UVlSsvojPois-xCFx_tGMwvXqOZLq7Tmp7F2THJAIxVjrlZzYG8cfQ9UZMWW-ZUfxhz0ECWWhSYqiUTW4xY0eCMu7bZsHjcfPqvU41nYDIdOEKV_q4HquBDp1bNmivgdQ3P3x_iSNRi6HHnKCkl3qhjwg.8mv1rDPf2l1oJ5Qg.dMEVm4TPZYWdcfj8_PJcMlo1QfL7AwXBx7VCrmeOF4a_Am94MAvE6CL0HfXYfmnf_-RnALYPFvaQNz4H8lcokrk3leT3F92iTACugkNYqOq0HkW1ya1r4zqoTp241P6SXDP5IT7XMT4Ai-qeKSjAcn4vJnu8WLFBlJnVZ9OiOzgLaCW4OiRrwGbDPvIUHIJvkSP9G3r2H5poUAEqHggl6TPu9_xdVmMQkf2_BylFvvfK1G3-EP6JH7T05pMg4FN-8H6CCSSt9semeoZG7_DTTsHcmqjoagZe_fgke38iiq4ZwMpwoxIZfA1ASUEXdUM4rGbs1yzNKro7i6QPMTchg2bzVkA1GDRskOzdQE3v3qaVsEfDgLgnNoumfobHYxLex8A6mgpQmv4nzgxjDGq8ysrBkMRdlo31RGkBffuhLSJSwED54v2u04tF9RwNlOEevVIdXyM_HFcCzDiNZyWodQe0xFrWLPPoOVOxElPsDPxqj5IwjR1vsZQwU0jWZv-7YwLl2GMwsLnEFiUG_4VWocDre_0pZulc-4RROEOv97wrfyDSoQtHqBim7blJ8YV9UEEIPTJsRSFTDlk0MJf8qXwbeFeFPzzhcyvEWjfMm6ePj3cNZnXa5JX4ebHog9Eqo2HUh0vWpqn19rFkE9wi_ka9k71zt2SvsKIeFJ0IbLJcf-GVjHSAu3Egbj-QVHDJS9uSaxXTQQ41Hd6-F53B4nUupgGCvpVnmp9POWgWaxT-93uJyWHtQFX0j6XNf5ar-lFoiByxCoydzaCQ3hi24R7_trM2Qb2osUM_3spo7Er2nNNxoanrshPSzcg0z-jmuiZomcyofjh142dblvtqFZ8-plNI-KJrCMqvQ-HaBt1uXg0ROkSEGzDIs2o32M2CettXVhS3XLTvmFjovxqh2mZcv34Hc8ySZTyGAFNn455kG8Xi9ANQaN_zjJGr0Vo9dTxJopUKqf1Vai592xTtHYCGbNOWWeenVOfL7s7lizZodJYi8J00jBvPtHWrzU0Io3ksx-L1JwECxYT6TdTewkpOYp8JIrYP-azu7IKwHgg5FbTI4CtAaww8EUYPu0l2Zjz9KjFv3lkuF6dWseQeh_X7rneb3hVoBvzNVkBBgigEPZfL6bIO4SrvMq6A1wXn7DVEoQsPu_pehhfxz16kVRXjHMWie2pRq6SUvdUWatWln-fvZJo0G9MJFVCbCf0e2Y3jHG6zLOzcXyhlPOesKsejyrm5zCdwDhuwKuTDfcXpLXFQGXiapQzR_naL6cL5JkD4Xpcv0R0o9hGGqfya77Zrfnf9Ys4Z0Wn7yYig8wNkK90trCgyPQ7LO0c.iol7SLf2jsoXZGma4Fb7Tw",
        "IdToken": "eyJraWQiOiJxZDVSckpxK2J1VGEzdDNxYnlQSHNmZVFZVXpwaEdXbFJVazh1UlpXRG5ZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIwYjcyN2U0Ny1mMzMyLTQ4ZGMtYTY5ZC0xZTE0NDFiMDJjMDUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYWRkcmVzcyI6eyJmb3JtYXR0ZWQiOiIxMjMgTWFpbiBTdCwgQW55dG93biwgVVNBIn0sImJpcnRoZGF0ZSI6IjE5ODktMTItMzEiLCJnZW5kZXIiOiJGZW1hbGUiLCJjdXN0b206bGFzdE5hbWUiOiJEb2UiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0xLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMV9zbGozNFNnZUsiLCJjb2duaXRvOnVzZXJuYW1lIjoiMGI3MjdlNDctZjMzMi00OGRjLWE2OWQtMWUxNDQxYjAyYzA1Iiwib3JpZ2luX2p0aSI6IjA1ZjFjZDlmLWU0NmUtNGMyOS1hNmZmLWExZTU3OTU3NmFiZSIsImF1ZCI6IjRkZWt1aTA4Nm1tdnNnNjE5dTJqbWQzbDVwIiwiZXZlbnRfaWQiOiI5MWJkNzJiMi01Y2RjLTQ1NzUtOGNkMi05MDEyNGU4YjM3NjkiLCJjdXN0b206Zmlyc3ROYW1lIjoiSm9obiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNzI0MDI2OTgzLCJuYW1lIjoiSm9obiBEb2UiLCJwaG9uZV9udW1iZXIiOiIrKzU1OTc0Nzg5OCIsImV4cCI6MTcyNDAzNDE4MywiaWF0IjoxNzI0MDI2OTgzLCJqdGkiOiJiYjBmZTZkZS1jNTYxLTRjNGUtOWIwZi01NmFlMzZjYmM4NmIiLCJlbWFpbCI6Imluc3Rhb21lcjgwN0B5b3BtYWlsLmNvbSJ9.uwI7e9_4eG-PIj6l6HSfPGlK4jAUbIZohFbMJmQWDnGSV5nv4iPQVevRqxq7FuwgApp07eklnQTuZlVy30gQ0Fokm7T0Fd7KZbAEUO23PXDc9Ont0Y4-OywLQSMZKbamV2yCkoP4mU8q-YFBfJIvfCJI6mz66ZbgVcmvhMcbSwfruBfEhLwJkQuR_pfmWBkYa4FKE49ToXHvcSLuCXXrWW8iv2eMfFiTjwj2PtrA64hsGPk9sEeLnccvuTq0dDQ9YXiLK9bynC48NHdhPjugzmcvJ87Gb_fZ9gpHmyLCkqc2BQy27ylUnUTXDU9Z1isgS-VyuY6rJ6nNXRwKjKlVww"
    },
    "userData": {
        "id": "66c284a7d4224f3a14c51b5b",
        "updatedAt": "2024-08-18T23:54:48.603Z",
        "createdAt": "2024-08-18T23:32:55.838Z",
        "firstName": "John",
        "lastName": "Doe",
        "dob": "1990-01-01T00:00:00.000Z",
        "address": "123 Main St, Anytown, USA",
        "email": "instaomer807@yopmail.com",
        "phoneNumber": "+5597478989",
        "company": "Example Corp",
        "uploadedW9Url": "https://example.com/w9.pdf",
        "uploadedNdaUrl": "https://example.com/nda.pdf",
        "uploadedOtherDocumentsUrls": [
            "https://example.com/doc1.pdf",
            "https://example.com/doc2.pdf"
        ],
        "contractSignedUrl": "https://example.com/contract.pdf",
        "privacyPolicyAgreed": true,
        "ndaSigned": "https://example.com/nda_signed.pdf",
        "username": "instaomer807@yopmail.com",
        "password": null,
        "temporaryPassword": "m68VXBT@",
        "authCode": "1918",
        "gender": "Female",
        "type": "Employee",
        "status": "Enabled",
        "promoCodeId": "66c284a7d4224f3a14c51b59",
        "cognitoId": "0b727e47-f332-48dc-a69d-1e1441b02c05",
        "salesPricingId": "66c284a7d4224f3a14c51b5e",
        "membershipId": "66c284a7d4224f3a14c51b56",
        "globalId": "0b727e47-f332-48dc-a69d-1e1441b02c05"
    }
}
          
          
//          result.auth.AccessToken
          
          setTokenDetails(tokenFormatter(result.auth));
          
//          setAdminRights({
//            providerRights: result?.adminProfile?.providerProfileAccessType,
//            patientRights: result?.adminProfile?.adminProfileAccessType,
          });

//          try {
//            const res = await GetAdminPersonalDetails();
//            setAdminDetail({
//              name: res?.name,
//              id: res?.id,
//            });
//            setIsLoading(false);
//            resetCredentials();
//            setActiveView(DASHBOARD_VIEW, BASE_DASHBOARD_PATH);
//          } catch (error) {
//            console.error("[GetAdminPersonalDetails][API_ERROR] =>", error);
//          }
//        })
//        .catch((err) => {
//          console.error(
//            "[SendVerificationReq][API_ERROR] -",
//            err?.response?.data?.errors || err
//          );
//          setIsLoading(false);
//          setApiError(err?.response?.data?.errors);
//          setIsDisabled(true);
//        });
    }
  };

  const handleResend = () => {
    const { email, password } = getCredentials();
    if (email && password) {
      SendLogInReq({
        email: email,
        password: password,
      })
        .then(async (res) => {
          const { authCode } = res;
          console.log(`[SendLogInReq][API_RESPONSE]`, authCode);
          setCredentials({ email, password, verification: authCode });
          try {
            const res = await GetAdminPersonalDetails();
            console.log("Res of the admin credentials are", res);
            setAdminDetail({
              name: res?.name,
              id: res?.id,
            });
          } catch (error) {
            console.error("[GetAdminPersonalDetails][API_ERROR] =>", error);
          }
        })
        .catch((err) => {
          setIsDisabled(true);
          setApiError(err?.response?.data?.errors);
          console.error(
            "[SendLogInReq_RESEND_CODE][API_ERROR] - ",
            err?.response?.data?.errors || err
          );
        });
    }
  };

  return (
    <>
      <div
        onClick={() => setActiveView(LOGIN_VIEW)}
        className="flex mb-12 items-center gap-3"
      >
        <BackIcon className="text-blue-chill cursor-pointer" />
        <span className="text-blue-chill text-base cursor-pointer"> Back</span>
      </div>
      <span className="sapphire mt-6 text-3xl font-extrabold font-poppins">
        Verification 2222
      </span>
      <br />
      <div className="text-blue-chill text-left font-medium font-poppins w-72">
        Please enter the code sent to your email
      </div>
      <div className="pt-4">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      </div>
      <div className="space-y-6 mt-8">
        <div
          className="flex flex-row justify-between gap-4 text-center Code_Boxs"
          onKeyPress={(e) => {
            if (e.key === "Enter" && codeVerificationChars?.length === 4) {
              handleSubmit();
            }
          }}
        >
          <OtpInput
            value={codeVerificationChars}
            onChange={(otp) => {
              setCodeVerificationChars(otp);
            }}
            numInputs={4}
            autoFocus={true}
            placeholder="XXXX"
            inputStyle={{
              border: "1px solid #45969f",
              borderRadius: "8px",
              width: "60px",
              height: "54px",
              fontSize: "16px",
              color: "#000",
              fontWeight: "300",
              caretColor: "#9298A9",
              marginLeft: "10px",
            }}
            focusStyle={{
              border: "1px solid #9298A9",
              outline: "none",
            }}
          />
        </div>
        {verification && <div>Your verification code is {verification}</div>}
        <div>
          <Button
            btnText="Verify"
            className={ClassNames("w-full py-2", !isDisabled && "text-white")}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={handleSubmit}
          />
          <div className="flex justify-end mt-3">
            <span
              onClick={() => handleResend()}
              className="text-blue-chill cursor-pointer"
            >
              Resend code
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
