import React from "react";
import "./Dashboard.css";
import "Colors.css";

export function Stats({ data, month, year }) {
  const getTotalVisits = () => {
    let result = 0;
    if (data?.length > 0) {
      data.forEach((i) => {
        result += i?.visits?.length ?? 0;
      });
    }
    return result;
  };

  const getTotalPayout = () => {
    let result = 0;
    if (data?.length > 0) {
      data.forEach((i) => {
        result = result + i?.totalCommission ?? 0;
      });
    }
    return result;
  };

  const stats = [
    {
      name: "Total Visits",
      stat: getTotalVisits(),
    },
    {
      name: `Projected Month Payout - ${month}/${year}`,
      stat: `$${getTotalPayout()}`,
    },
  ];

  return (
    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2">
      {stats.map((item) => (
        <div
          key={item.name}
          className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
        >
          <dt className="truncate text-sm font-medium text-gray-500">
            {item.name}
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {item.stat}
          </dd>
        </div>
      ))}
    </dl>
  );
}
