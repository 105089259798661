import SignIn from "Containers/Login";
import Invite from "Containers/Invite";
import Payouts from "Containers/Payouts";
import Marketing from "Containers/Marketing";
import Support from "Containers/Support";
import ProtectedRoute from "ProtectedRoutes";
import { LoginProvider } from "Context/Login";
import { TokenProvider } from "Context/Global";
import { Dashboard } from "Containers/Dashboard";
import { GetToken } from "Constants/LocalStorage";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {
  BASE_DASHBOARD_PATH,
  EMAIL_INVITES_PATH,
  PAYOUTS_PATH,
  MARKETING_PATH,
  SUPPORT_PATH,
} from "Constants/Views";

import "./App.css";

function App() {
  const isAuthenticated = GetToken();
  return (
    <BrowserRouter>
      <TokenProvider>
        <Switch>
          <ProtectedRoute path={BASE_DASHBOARD_PATH} component={() => <Dashboard />} />
          <ProtectedRoute path={EMAIL_INVITES_PATH} component={() => <Invite />} />
          <ProtectedRoute path={PAYOUTS_PATH} component={() => <Payouts />} />
          <ProtectedRoute path={MARKETING_PATH} component={() => <Marketing />} />
          <ProtectedRoute path={SUPPORT_PATH} component={() => <Support />} />

          <Route
            exact
            path="/"
            render={() =>
              isAuthenticated ? (
                <Dashboard />
              ) : (
                <LoginProvider>
                  <SignIn />
                </LoginProvider>
              )
            }
          />
          <Redirect from="*" to="/" />
        </Switch>
      </TokenProvider>
    </BrowserRouter>
  );
}

export default App;
