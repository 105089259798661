import { SUPPORT_PATH } from "Constants/Views";
import { SupportPage } from "Pages/Support/Support";
import { Route, Switch } from "react-router";
import { useGlobalContext } from "Context/Global";

export default function Support() {
//  const { getAdminRights } = useGlobalContext();
//  const { providerRights } = getAdminRights();
  return (
    <Switch>
      <Route
        exact
        path={SUPPORT_PATH}
        render={() => <SupportPage />}
      />
    </Switch>
  );
}
